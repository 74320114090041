import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { Section } from '../../styled-components';

const MainCont = styled(Section)`
  background-image: url("${require("../../images/mundo.png")}");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  min-height: 30vh;
  height: 80vh;
  max-width: 100%;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    background-position: center top;
    background-size: contain;
    background-repeat: no-repeat; /* Asegura que no se repita en móviles */
    height: auto;
    background-attachment: scroll;
  }
`;

export default ()=> {
  return(
    <MainCont>
      <Container>

      </Container>
    </MainCont>
  )
}